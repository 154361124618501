<template>
  <div class="friendsCircle_explain_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>

    <van-collapse v-model="activeName" accordion>
      <van-collapse-item v-for="(item, index) in list" :key="index" :title="item.manualTitle" :name="index + 1">
        <div v-html="item.manualContentsHtml"></div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader
  },
  data() {
    return {
      title: this.$route.meta.title,
      list: [],
      pagination: {
        pageNo: 1,
        pageSize: 100,
      },
      activeName: '1',
    }
  },
  created() {
    this.onLoad()
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    async onLoad() {
      const res = await this.$HTTP.post(this, 'API_FORUM_MANUAL_ANON_LISTS', {
        // "forumManualId": 0,
        // "manualTitle": "string",
        // "sortBy": "string",
        // "sortIndex": 0,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      }, {}, true) || []
      console.log('res', res);
      for (let item of res.data) {
        try {
          item.manualContentsHtml = item.manualContents ? this.htmlDecodeByRegExp(item.manualContents) : ''
        } catch (e) {
          item.manualContentsHtml = ''
        }
      }
      this.list = res.data
    },
    // 使用正则实现html解码
    htmlDecodeByRegExp(str) {
      var s = '';
      if (str.length === 0) {
        return '';
      }
      s = str.replace(/&amp;/g, '&');
      s = s.replace(/&lt;/g, '<');
      s = s.replace(/&gt;/g, '>');
      s = s.replace(/&nbsp;/g, ' ');
      s = s.replace(/&#39;/g, '\'');
      s = s.replace(/&quot;/g, '\"');
      return s;
    },
  }
};
</script>

<style lang="less">
.awardBook_page {
}
</style>
